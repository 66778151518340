import React from 'react'
import * as classes from './NumericInput.module.scss'

const NumericInput = ({
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}) => {
  return (
    <div className={classes.wrap}>
      <button
        disabled={disabled}
        className={classes.decrement}
        aria-label="Decrement"
        onClick={onDecrement}
      >
        <span>-</span>
      </button>
      <input
        disabled={disabled}
        type="numeric"
        className={[classes.input, className].join(' ')}
        {...props}
      />
      <button
        disabled={disabled}
        className={classes.increment}
        aria-label="Increment"
        onClick={onIncrement}
      >
        <span>+</span>
      </button>
    </div>
  )
}

export default NumericInput
