import React, { useContext } from 'react'
import { StoreContext } from '~/context/StoreContext'

import * as classes from './AddToCart.module.scss'

const AddToCart = ({ variantId, quantity, available, ...props }) => {
  const { addVariantToCart, loading } = useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <button
      type="submit"
      className={classes.AddToCart}
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? 'Add to Cart' : 'Out of Stock'}
    </button>
  )
}

export default AddToCart
