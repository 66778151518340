import React, { useState, useEffect } from 'react'
import isEqual from 'lodash.isequal'

const useProductForm = product => {
  const [variant, setVariant] = useState({})
  const [quantity, setQuantity] = useState(1)
  const [available, setAvailable] = useState(false)
  const [hasVariants, setHasVariants] = useState(false)

  useEffect(() => {
    if (Object.keys(product).length === 0) {
      return
    }

    // Default variant, first in array
    const {
      variants: [initialVariant],
    } = product

    setVariant({ ...initialVariant })
    setAvailable(initialVariant.available)
    setHasVariants(product.variants.length >= 1)
  }, [product])

  // Handle variant change (select onChange)
  const handleOptionChange = (index, event) => {
    const optionSelected = event.target.value
    if (optionSelected === '') {
      return
    }

    // Get selectedOption from acutal variant
    const currentOptions = [...variant.selectedOptions]

    // modify selectedOption from acutal variant with new selected value
    // index is used in case there are mutliple options
    currentOptions[index] = {
      ...currentOptions[index],
      value: optionSelected,
    }

    // Find variant based on option selected
    const selectedVariant = product.variants.find(variant => {
      const foundOptions = [...variant.selectedOptions]

      foundOptions[index] = {
        ...foundOptions[index],
        value: variant.selectedOptions[0].value,
      }
      return isEqual(currentOptions, foundOptions)
    })

    setVariant({ ...selectedVariant })
    setAvailable(selectedVariant.available)
  }

  return {
    available,
    handleOptionChange,
    hasVariants,
    quantity,
    setQuantity,
    variant,
  }
}

export default useProductForm
