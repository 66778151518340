import React from 'react'
import { object } from 'prop-types'

import { formatPrice } from '~/utils/format-price'
import AddToCart from '~/components/AddToCart'
import NumericInput from '~/components/NumericInput/NumericInput'

import useProductForm from './hooks/useProductForm'

import * as classes from './ProductForm.module.scss'

const ProductForm = ({ product }) => {
  const {
    available,
    handleOptionChange,
    hasVariants,
    quantity,
    setQuantity,
    variant,
  } = useProductForm(product)

  // Make sure product object isn't empty
  if (Object.keys(product).length === 0) {
    return null
  }

  return (
    <div className={classes.productForm}>
      {Math.round(variant.price) !== 0 && (
        <div className={classes.price}>{formatPrice('NZD', variant.price)}</div>
      )}
      <fieldset>
        {hasVariants &&
          product.options.map(({ id, name, values }, index) => (
            <div key={id}>
              <select
                aria-label="Variants"
                onChange={event => handleOptionChange(index, event)}
                className={classes.selector}
                value={variant.title}
              >
                <option value="" disabled>{`Select ${name}`}</option>
                {values.map(value => (
                  <option value={value.value} key={`${name}-${value.value}`}>
                    {value.value}
                  </option>
                ))}
              </select>
            </div>
          ))}
      </fieldset>
      <NumericInput
        aria-label="Quantity"
        onIncrement={() => setQuantity(q => Math.min(q + 1, 20))}
        onDecrement={() => setQuantity(q => Math.max(1, q - 1))}
        onChange={event => setQuantity(event.currentTarget.value)}
        value={quantity}
        min="1"
        max="20"
      />
      <AddToCart
        variantId={variant.id}
        quantity={quantity}
        available={available}
      />
    </div>
  )
}

ProductForm.prototype = {
  product: object,
}

export default ProductForm
