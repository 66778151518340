import React, { useContext, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import InnerImageZoom from 'react-inner-image-zoom'

import { StoreContext } from '~/context/StoreContext'
import Container from '~/components/Container'
import ProductForm from '~/components/ProductForm'
import Layout from '~/components/Layout'
import Seo from '~/components/Seo'

import * as classes from './ProductPage.module.scss'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

const ProductPage = ({ data }) => {
  const {
    title,
    subtitle,
    main_image,
    close_up_image,
    description,
    product_table,
    shopify,
    coming_soon: comingSoon,
    seo_title: seoTitle,
    seo_description: seoDescription,
  } = data.prismicProduct.data

  const { client } = useContext(StoreContext)

  const [shopifyProduct, setShopifyProduct] = useState({})

  // Fetch Shopify details
  useEffect(() => {
    client.product
      .fetchByHandle(shopify.handle)
      .then(product => {
        setShopifyProduct(product)
      })
      .catch(e => console.error(e))
  }, [])

  // calculate height of imagr to avoid layout shift
  const paddingBottomBefore =
    (main_image?.dimensions?.height / main_image?.dimensions?.width) * 100 || 0

  return (
    <Layout>
      <Seo title={seoTitle?.text} description={seoDescription?.text} />
      <Container>
        <div className={classes.mainImageContainer}>
          <div
            className={classes.before}
            style={{
              paddingBottom: `${paddingBottomBefore}%`,
            }}
          />
          <InnerImageZoom
            src={main_image.gatsbyImageData.images.fallback.src}
            zoomSrc={main_image?.fixed?.src}
            srcSet={main_image.gatsbyImageData.images.fallback.srcSet}
            alt={main_image.alt}
            zoomScale={1}
            fullscreenOnMobile={true}
            className={classes.mainImage}
          />
        </div>
        <div className={classes.productBlock}>
          <div className={classes.productTitle}>
            <h1>{title.text}</h1>
            {subtitle && <h2>{subtitle.text}</h2>}
          </div>
          {comingSoon ? (
            <span className={classes.comingSoon}>Coming soon</span>
          ) : (
            <ProductForm product={shopifyProduct} />
          )}
        </div>
        <div className={classes.productBottom}>
          <GatsbyImage
            image={close_up_image.gatsbyImageData}
            alt={close_up_image.alt || title.text}
          />
          <div className={classes.productInfo}>
            <div className={classes.productInfoLine}>
              <span className={classes.label}>Description</span>
              {description.raw && (
                <div className={classes.info}>
                  <RichText render={description.raw} />
                </div>
              )}
            </div>
            {product_table &&
              product_table.map(line => (
                <div key={line.label.text} className={classes.productInfoLine}>
                  <span className={classes.label}>{line.label.text}</span>
                  <div className={classes.info}>
                    <RichText render={line.info.raw} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query product($id: String) {
    prismicProduct(id: { eq: $id }) {
      id
      uid
      data {
        seo_description {
          text
        }
        seo_title {
          text
        }
        coming_soon
        main_image {
          alt
          dimensions {
            height
            width
          }
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          fixed(width: 3000, imgixParams: { q: 70 }) {
            src
          }
        }
        close_up_image {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            sizes: "50vw"
            aspectRatio: 1
          )
        }
        product_table {
          info {
            raw
          }
          label {
            text
          }
        }
        subtitle {
          text
        }
        title {
          text
        }
        description {
          raw
        }
        shopify {
          title
          status
          options {
            id
            name
            position
            product_id
            values
          }
          handle
          id
          prismicId
          body_html
          variants {
            option1
            price
            position
            product_id
            sku
            title
          }
        }
      }
    }
  }
`
