// extracted by mini-css-extract-plugin
export var productBlock = "ProductPage-module--productBlock--ktvjG";
export var mainImageContainer = "ProductPage-module--mainImageContainer--xvhpA";
export var before = "ProductPage-module--before--SwXtA";
export var mainImage = "ProductPage-module--mainImage--pHvQE";
export var productTitle = "ProductPage-module--productTitle--l8--c";
export var productBottom = "ProductPage-module--productBottom--s-6Ir";
export var comingSoon = "ProductPage-module--comingSoon--zqJWb";
export var productInfoLine = "ProductPage-module--productInfoLine--q5SDo";
export var label = "ProductPage-module--label--GTRYy";
export var info = "ProductPage-module--info--QOPCZ";